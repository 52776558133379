import React, { Component } from 'react';
import ReactToPrint from 'react-to-print';
import InfpCompoPrint from './InfpCompoPrint';

class PrintInfoUser extends Component {
  render() {
    return (
      <div>
        <ReactToPrint
          trigger={() => <button >הדפסת פרטים אישיים</button>}
          content={() => this.componentRef}
        />
        <div style={{ display: "none" }}><InfpCompoPrint Person={this.props.Person} ref={el => (this.componentRef = el)} /></div>
        {/* <div ><InfpCompoPrint Person={this.props.Person} ref={el => (this.componentRef = el)} /></div> */}

      </div>
    );
  }
}

export default PrintInfoUser;