import React from 'react';
import './InfpCompoPrint.css'

class InfpCompoPrint extends React.Component {
  render() {
    return (
      <div className='InfpCompoPrint'>
        <div className="infoText">
          <div className="divImg">
            <img className='img' src={'https://yadbeyad.org/wp-content/uploads/2017/03/%D7%99%D7%93-%D7%91%D7%99%D7%93-%D7%90%D7%99%D7%9B%D7%95%D7%AA-%D7%92%D7%91%D7%95%D7%94%D7%94.png'} alt='logo' />
          </div>
          <div style={{ textAlign: "center" }}>
            <p><b style={{ fontFamily: "Garamond", color: "black" }}>פרטים אישיים: </b></p>
          </div>
          <p>ת.ז:{'  ' + this.props.Person.PersonID}</p>
          <p>שם משפחה:{'  ' + this.props.Person.LastName}</p>
          <p>שם פרטי:{'  ' + this.props.Person.FirstName}</p>
          <p>מצב משפחתי:{'  ' + this.props.Person.MaritalStatus}</p>
        </div>
      </div>
    );
  }
}

export default InfpCompoPrint;