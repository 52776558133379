import React, { Component } from 'react';
import axios from "axios";
import './Backup.css';
import Button from '@material-ui/core/Button';

class Backup extends Component {
   
    People = () => {
    const config = { headers: { Authorization: `Bearer ${this.props.User.Token}` } }
        axios.post('./find/',
        {}, config)
        .then(res => {
            // console.log(res.data);
            this.backup(res.data, `גיבוי כללי${'  '} ${this.newDate()}`)
        })
        .catch()
      }
      Holidays = () => {
        axios.get('/Holiday')
        .then(res => {
        //  console.log(res.data);
         this.backup(res.data, `גיבוי חגים${'  '} ${this.newDate()}`)
        })
        .catch()
      }

      newDate = () => {
        const d = new Date();
        const date = d.getDate();
        const month = d.getMonth() + 1;
        const year = d.getFullYear();
        return  date + "_" + month + "_" + year
      }

      backup = (exportObj, exportName) =>{
        let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
        let downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href",     dataStr);
        downloadAnchorNode.setAttribute("download", exportName + ".json");
        document.body.appendChild(downloadAnchorNode);
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
      }


    render() {
        return (
            <div className='Backup'>
              <Button variant="contained" color="primary" onMouseDown={() => { this.People()}} >גיבוי כללי</Button>
              <br></br><br></br><br></br>
              <Button variant="contained" color="primary" onMouseDown={() => { this.Holidays()}} >גיבוי חגים</Button>
            </div>
        );
    }
}

export default Backup;