import React, { Component } from 'react';
import axios from "axios";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import Alert from './Alert';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
// import { createEditor } from 'slate';
// import { Slate, Editable, withReact } from 'slate-react';


class UpdateCiti extends Component {
  state = {
    value: {
      type: 'paragraph',
      children: [{ text: 'A line of text in a paragraph.' }],
    },
    textarea: "", HolidaysList: [], HolidayValue: null, Holidays: null, Name: '', Y: '', DateHoliday: null, Streets: [], Street: null, City: null, Cities: [], NewStreet: '', CityId: '', DleStreet: ''
  }
  HeaderHolidays() {
    let Headlines = ['שם אירוע', 'מחיקה']
    return Headlines.map((title, index) => {
      return <th key={index}>{title}</th>
    })
  }
  componentDidMount() {
    axios.get('/Holiday')
      .then(res => {
        console.log("res", res);
        this.setState({ Holidays: res.data });
      })
      .catch()
  }
  myHolidays() {
    let listHolidays = this.state.Holidays
    if (listHolidays) {
      return listHolidays.map((ListObject, index) => {
        return (
          <tr key={index}>
            <td>{ListObject.Name + '-' + ListObject.Y}</td>
            <td><button onMouseDown={() => {
              const url = `${'./Holiday/'}${ListObject._id}`;
              axios.delete(url)
                .then(function (res) { console.log(res) })
                .catch()
            }}>  <i className="fas fa-trash"></i>
            </button></td>
          </tr>
        )
      })
    }
  }



  // ***********************************************
  clearPosInputs = () => { this.setState({ Name: '', Y: '', DateHoliday: null }) }
  DateHoliday = date => {
    this.setState({ DateOFHoliday: null })
    this.setState({ DateHoliday: date })
    if (date !== null) {
      if (date.toString() !== 'Invalid Date') {
        this.DateOFHoliday(date)
      }
    }
  };
  DateOFHoliday = date => {
    var today = new Date(date);
    var ThisY = new Date().toISOString().substring(0, 4);
    var dd = checkTime(today.getDate());
    var mm = checkTime(today.getMonth() + 1)
    var yyyy = today.getFullYear();
    function checkTime(i) { if (i < 10) { i = "0" + i; } return i; }
    if (Number(yyyy) < Number(ThisY) || Number(yyyy) > Number(ThisY) + 1) { }
    else {
      var dateTime = dd + '/' + mm + '/' + yyyy;
      this.setState({ DateOFHoliday: dateTime })
    }
  }
  cancelAlert = () => { this.setState({ Alert: null }) }

  ALert = (colorAlert, AlertText, icon, header) => {
    this.setState({ Alert: true, colorAlert: colorAlert, header: header, AlertText: AlertText, icon: icon })
  }
  isDisabled = () => {
    var date = new Date(), yyyy = date.getFullYear();
    return (this.state.Name === '' || this.state.Y < Number(yyyy) || this.state.Y > Number(yyyy) + 1 || this.state.DateOFHoliday === null)
  }
  // ******************************************************************************
  LastHoliday = () => {
    axios.get('/LastHoliday')
      .then(res => { this.setState({ HolidaysList: res.data }); })
      .catch()
  }
  up = () => {
    const config = { headers: { Authorization: `Bearer ${this.props.User.Token}` } };
    const url = `${'/Holiday/'}${this.state.HolidayValue._id}`;
    axios.put(url, {
      PartitionTime: this.state.textarea
    }, config)
      .then(response => {
        if (response.status === 200) {
          alert('עודכן בהצלחה')
        }
        else {
        }
      })
      .catch();
  }
  render() {
    const disabled = this.isDisabled();

    return (
      <div>
        <div style={{ width: '600px', margin: 'auto' }}>
          <div id='AutocompleteCity' className="group" style={{ float: 'right' }}>
            <Autocomplete style={{ width: 155 }}
              className="Autocomplete"
              value={this.state.City}
              onChange={(e, value) => {
                this.setState({ Streets: [], Street: null });
                if (value === null || value === '' || value === []) {
                  this.setState({ City: null, Streets: [], Street: null });
                }
                else {
                  this.setState({ City: value, Streets: value.שם_רחוב, CityId: value._id });
                  console.log(value);
                }
              }}
              options={this.state.Cities}
              getOptionLabel={option => option.שם_ישוב}
              renderInput={params => <TextField
                onChange={e => {
                  if (e.target.value === '') { }
                  else {
                    axios.post('/GetCities/', { שם_ישוב: { $regex: e.target.value } })
                      .then(city => {
                        this.setState({ Cities: city.data });
                      })
                      .catch(function (error) {
                        this.setState({ Cities: [] });
                      })
                  }
                }}
                {...params} label="עיר" />}
            /> </div>
          <div id='AutocompleteCity' className="group" style={{ float: 'left' }}>
            <Autocomplete style={{ width: 155 }}
              value={this.state.Street}
              className="Autocomplete"
              onChange={(e, value) => {
                if (value === null || value === '' || value === []) {
                  this.setState({ Street: null });
                }
                else {
                  this.setState({ Street: value });
                }
              }
              }
              options={this.state.Streets}
              getOptionLabel={option => option}
              renderInput={params => <TextField
                {...params} label="רחוב" />}
            /> </div>
        </div>
        {/* <br /><br /><br /><br /> */}
        <input onChange={e => this.setState({ NewStreet: e.target.value })} ></input>
        {/* <br /><br /><br /><br /> */}
        <button onClick={() => {
          let araay = [...this.state.Streets]
          araay.push(this.state.NewStreet);
          const url = `${'./Cities/'}${this.state.CityId}`;
          axios.put(url, { שם_רחוב: araay })
            .then(response => {
              if (response.status === 200) {
                alert('עודכן בהצלחה');
              }
              else {
                console.log(`status is not 200 : ${response.status}`);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }

        }>update</button>
        {/* <br /><br /><br /><br />

        <input onChange={e => this.setState({ DleStreet: e.target.value })} ></input>
        <br /><br /><br /><br />

        <button onClick={() => {
          let araay = [...this.state.Streets]
          let araayStreet = araay.filter(x => x !== this.state.DleStreet)
          const url = `${'./Cities/'}${this.state.CityId}`;
          axios.put(url, { שם_רחוב: araayStreet })

            .then(response => {
              if (response.status === 200) {
                alert('עודכן בהצלחה');
              }
              else {
                console.log(`status is not 200 : ${response.status}`);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }

        }>מחיקה</button> */}
        <br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br><br></br>
        <table id='Holidays' style={{ width: '600px', margin: 'auto' }}>
          <tbody>
            <tr>{this.HeaderHolidays()}</tr>
            {this.myHolidays()}
          </tbody>
        </table>
        <br></br><br></br><br></br>

        <Autocomplete
          className="Autocomplete"
          value={null}
          onChange={(e, value) => {
            if (value === null || value === '' || value === []) {
              this.setState({ HolidayValue: null })
            }
            else {
              console.log(value);
              this.setState({ HolidayValue: value, textarea: value.PartitionTime })

            }
          }}
          options={this.state.HolidaysList}
          getOptionLabel={option => option.Name + ' - ' + option.Y}
          style={{ width: '130px' }}
          renderInput={params => <TextField
            onChange={e => {
              if (e.target.value === '') { this.LastHoliday() }
              else {
                axios.get('/Holiday/', { $regex: e.target.value })
                  .then(H => { this.setState({ HolidaysList: H.data }); })
                  .catch(function () { this.setState({ HolidaysList: [] }); })
              }
            }}
            {...params} label="אירוע" />}
        />

        <br></br><br></br><br></br><br></br><br></br><br></br>
        <textarea value={this.state.textarea} onChange={e => this.setState({ textarea: e.target.value })} />
        <br></br><br></br>
        <textarea readOnly value={this.state.textarea} />
        <br></br><br></br><br></br><br></br><br></br><br></br>
        <button onClick={() => { this.up() }}>upup</button>

        <br></br><br></br><br></br><br></br><br></br><br></br>


        {/* ******************************************************************* */}
        <div className='ADD'>
          <h2>הוספת חג</h2>
          <FormControl style={{ width: "120px" }}><InputLabel>בחר חג</InputLabel>
            <Select style={{ textAlign: "initial" }} value={this.state.Name} onChange={e => { this.setState({ Name: e.target.value }) }}>
              <MenuItem value={'פסח'}>פסח</MenuItem><MenuItem value={'ראש השנה'}>ראש השנה</MenuItem>
              <MenuItem value={'רמדאן'}>רמדאן</MenuItem></Select></FormControl>

          {/* <input placeholder='שם חג (לא שנה!!)' value={this.state.Name} onChange={(event) => { this.setState({ Name: event.target.value }) }}></input> */}
          <br />
          <br />
          <div className="group" id='HolidayDate'>
            <MuiPickersUtilsProvider utils={DateFnsUtils} ><KeyboardDatePicker labelstyle={{ right: '0' }} label="תאריך החג"
              format="dd/MM/yyyy" value={this.state.DateHoliday} style={{ verticalAlign: 'sub', width: '145px', right: '0', direction: 'rtl' }} onChange={this.DateHoliday} /></MuiPickersUtilsProvider>
          </div>
          <br />
          <br />
          <input style={{ width: "150px" }} placeholder='שנה' value={this.state.Y} type="number" onChange={(event) => { this.setState({ Y: event.target.value }) }}></input>
          <br />
          <br />
          <Button
            variant="contained" color="primary" size="small"
            disabled={disabled} onMouseDown={() => {
              const config = { headers: { Authorization: `Bearer ${this.props.User.Token}` } }
              let length = null;
              let ALert = this.ALert
              let clearPosInputs = this.clearPosInputs
              axios.get('/Holiday')
                .then(res => {
                  this.setState({ HolidaysList: res.data });
                  let last = res.data.slice(-1)[0];
                  length = last.ID;
                  axios
                    .post('/Holiday', { ID: length + 1, Name: this.state.Name, Y: this.state.Y, DateOFHoliday: this.state.DateOFHoliday, Status: false, PackageType: undefined }, config)
                    .then(function (res) {
                      if (res.data.Status === '201') {
                        ALert('Alertgreen', 'החג נוסף בהצלחה!', 'far fa-thumbs-up')
                        clearPosInputs()
                      }
                      else {
                        ALert('Alertred', res.data.Results, 'fas fa-exclamation-circle', 'שגיאה')
                      }
                    })
                    .catch()
                })
                .catch()
            }} >הוסף</Button>

        </div>
        {this.state.Alert && <Alert colorAlert={this.state.colorAlert} header={this.state.header} cancelAlert={this.cancelAlert} AlertText={this.state.AlertText} icon={this.state.icon} />}
        <br></br><br></br><br></br><br></br><br></br>

      </div>
    );
  }
}

export default UpdateCiti;