import React from 'react';
import './ComponentToPrint.css'
import { QRCode } from 'react-qr-svg';
import axios from "axios";

class ComponentToPrint extends React.Component {
  state={ LastHoliday: "" }
  componentDidMount(){
      axios.get('/LastHoliday')
        .then(res => {
          // console.log(res.data[0]);
          this.setState({ LastHoliday: res.data[0] });
        })
        .catch()

  }

  datePrint (){
    var today = new Date();
    var dd = today.getDate()
    var mm = today.getMonth() + 1
    var yyyy = today.getFullYear().toString().slice(-2);
      return dd + '.' + mm + '.' + yyyy;
  }

  render() {
    const date = this.datePrint();
    return (
      <div className='ComponentToPrint'>
        <div>
          <div className="logo">
            <div className="divImg">
            <img className='img' src={'https://yadbeyad.org/wp-content/uploads/2017/03/%D7%99%D7%93-%D7%91%D7%99%D7%93-%D7%90%D7%99%D7%9B%D7%95%D7%AA-%D7%92%D7%91%D7%95%D7%94%D7%94.png'} alt='logo' />
            </div>
            <h3 className="ID">ת.ז: {'  ' + this.props.Person.PersonID}</h3>
            <div >
              <QRCode
                className='QRCode'
                // string ('L' 'M' 'Q' 'H')
                level="L"
                style={{ width: 90 }}
                value={JSON.stringify({
                  PersonID: this.props.Person.PersonID,
                  DateOFHoliday: this.props.ListObject.DateOFHoliday
                })}
              />
            </div>
            <div style={{marginTop: '10px',borderRadius: '10px', backgroundColor: 'green', color: 'white'}}>נוצר ב {date}</div>
            <h3 style={{ margin: '0', fontFamily: 'monospace'}}>חבילה {' ' + this.props.ListObject.PackageType}</h3>
            <div className="info">
              {this.state.LastHoliday.PartitionTime && 
            <textarea style={{ resize: 'none', border: "none", width: '-webkit-fill-available', height: '-webkit-fill-available'}} readOnly value={this.state.LastHoliday.PartitionTime} /> }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ComponentToPrint;