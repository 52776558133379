import React, { Component } from 'react';
import axios from "axios";
// import cleaner from 'deep-cleaner';
import './AddHoliday.css';
import Button from '@material-ui/core/Button';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { Redirect } from 'react-router-dom';
import Alert from './Alert';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
// import ReactToExcel from 'react-html-table-to-excel';
import { jssPreset, StylesProvider } from '@material-ui/core/styles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import rtl from 'jss-rtl';
import { create } from 'jss';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const theme = createMuiTheme({
  direction: 'rtl'
});

class AddHoliday extends Component {
  state = {
    DateHoliday: null, Alert: null, colorAlert: '', header: '', AlertText: '', icon: '', nameH: "",
    textarea: "", HolidaysList: [], HolidayValue: null, navigaToHome: false, autocomplete: true
  }

  cancelAlert = () => { this.setState({ Alert: null }) }
  ALert = (colorAlert, AlertText, icon, header) => {
    this.setState({ Alert: true, colorAlert: colorAlert, header: header, AlertText: AlertText, icon: icon })
  }

  componentDidMount() { this.LastHoliday() }
  LastHoliday = () => {
    axios.get('/LastHoliday')
      .then(res => {
        this.setState({ HolidaysList: res.data });
      })
      .catch()
  }

  up = () => {
    const config = { headers: { Authorization: `Bearer ${this.props.User.Token}` } };
    const url = `${'/Holiday/'}${this.state.HolidayValue._id}`;
    let ALert = this.ALert;
    let _this = this;
    axios.put(url, {
      PartitionTime: this.state.textarea,
      Name: this.state.nameH
    }, config)
      .then(response => {
        if (response.status === 200) {
          ALert('Alertgreen', 'הטקסט עודכן בהצלחה!', 'far fa-thumbs-up')
          this.LastHoliday()
          _this.setState({ autocomplete: true, textarea: "" })
        }
        else {
        }
      })
      .catch();
  }

  clearPosInputs = () => { this.setState({ Name: '', Y: '', DateHoliday: null }) }


  isDisabled = () => {
    var date = new Date(), yyyy = date.getFullYear();
    return (this.state.Name === '' || this.state.Y < Number(yyyy) || this.state.Y > Number(yyyy) + 1 || this.state.DateOFHoliday === null || (!this.state.DateOFHoliday))
  }

  DateOFHoliday = date => {
    var today = new Date(date);
    var ThisY = new Date().toISOString().substring(0, 4);
    var dd = checkTime(today.getDate());
    var mm = checkTime(today.getMonth() + 1)
    var yyyy = today.getFullYear();
    function checkTime(i) { if (i < 10) { i = "0" + i; } return i; }
    if (Number(yyyy) < Number(ThisY) || Number(yyyy) > Number(ThisY) + 1) { }
    else {
      var dateTime = dd + '/' + mm + '/' + yyyy;
      this.setState({ DateOFHoliday: dateTime })
    }
  }

  DateHoliday = date => {
    this.setState({ DateOFHoliday: null })
    this.setState({ DateHoliday: date })
    if (date !== null) {
      if (date.toString() !== 'Invalid Date') {
        this.DateOFHoliday(date)
      }
    }
  };

  render() {
    const disabled = this.isDisabled();
    if (this.state.navigaToHome) { return <Redirect to='./Home' /> }
    return (
      <div className='AddHoliday' style={{ padding: '2vw' }}>
        <StylesProvider jss={jss}>
          <ThemeProvider theme={theme}>

            {/* ******************************************************************* */}
            <div className='ADD'>
              <h2>הוספת חג</h2>
              <FormControl style={{ width: "120px" }}><InputLabel>בחר חג</InputLabel>
                <Select style={{ textAlign: "initial" }} value={this.state.Name} onChange={e => { this.setState({ Name: e.target.value }) }}>
                  <MenuItem value={'פסח'}>פסח</MenuItem><MenuItem value={'ראש השנה'}>ראש השנה</MenuItem>
                  <MenuItem value={'רמדאן'}>רמדאן</MenuItem></Select></FormControl>

              {/* <input placeholder='שם חג (לא שנה!!)' value={this.state.Name} onChange={(event) => { this.setState({ Name: event.target.value }) }}></input> */}
              <br />
              <br />
              <div className="group" id='HolidayDate'>
                <MuiPickersUtilsProvider utils={DateFnsUtils} ><KeyboardDatePicker labelstyle={{ right: '0' }} label="תאריך החג"
                  format="dd/MM/yyyy" value={this.state.DateHoliday} style={{ verticalAlign: 'sub', width: '145px', right: '0', direction: 'rtl' }} onChange={this.DateHoliday} /></MuiPickersUtilsProvider>
              </div>
              <br />
              <br />
              <input style={{ width: "150px" }} placeholder='שנה' value={this.state.Y} type="number" onChange={(event) => { this.setState({ Y: event.target.value }) }}></input>
              <br />
              <br />
              <Button
                variant="contained" color="primary" size="small"
                disabled={disabled} onMouseDown={() => {
                  console.log(this.state.DateHoliday);
                  const config = { headers: { Authorization: `Bearer ${this.props.User.Token}` } }
                  let length = null;
                  let ALert2 = this.ALert
                  let clearPosInputs = this.clearPosInputs
                  axios.get('/Holiday')
                    .then(res => {
                      this.setState({ HolidaysList: res.data });
                      let last = res.data.slice(-1)[0] ;
                      length = last.ID;
                      console.log({ ID: length + 1, Name: this.state.Name, Y: this.state.Y, DateOFHoliday: this.state.DateOFHoliday, Status: false, PackageType: undefined });
                      axios
                        .post('/Holiday', { ID: length + 1, Name: this.state.Name, Y: this.state.Y, DateOFHoliday: this.state.DateOFHoliday, Status: false, PackageType: undefined }, config)
                        .then(function (res) {
                          if (res.data.Status === '201') {
                            ALert2('Alertgreen', 'החג נוסף בהצלחה!', 'far fa-thumbs-up')
                            clearPosInputs()
                          }
                          else {
                            ALert2('Alertred', res.data.Results, 'fas fa-exclamation-circle', 'שגיאה')
                          }
                        })
                        .catch()
                    })
                    .catch()
                }} >הוסף</Button>

            </div>
            {this.state.Alert && <Alert colorAlert={this.state.colorAlert} header={this.state.header} cancelAlert={this.cancelAlert} AlertText={this.state.AlertText} icon={this.state.icon} />}
            <br></br><br></br><br></br>

            <div className='PartitionTime'>
              <h2>הוספת ועריכת זמני חלוקה</h2>
              {this.state.autocomplete &&
                <Autocomplete
                  className="Autocomplete"
                  value={null}
                  onChange={(e, value) => {
                    if (value === null || value === '' || value === []) {
                      this.setState({ HolidayValue: null })
                    }
                    else {
                      this.setState({ HolidayValue: value, nameH: value.Name, textarea: value.PartitionTime, autocomplete: false })

                    }
                  }}
                  options={this.state.HolidaysList}
                  getOptionLabel={option => option.Name + ' - ' + option.Y}
                  style={{ width: '130px' }}
                  renderInput={params => <TextField
                    onChange={e => {
                      if (e.target.value === '') { this.LastHoliday() }
                      else {
                        axios.get('/Holiday/', { $regex: e.target.value })
                          .then(H => { this.setState({ HolidaysList: H.data }); })
                          .catch(function () { this.setState({ HolidaysList: [] }); })
                      }
                    }}
                    {...params} label="בחר חג" />}
                />}
              {!this.state.autocomplete && <div> <h3> חג: {' ' + this.state.HolidayValue.Name + ' - ' + this.state.HolidayValue.Y}</h3>
                <p>ערוך טקסט:</p>
                <textarea style={{ resize: "none", height: "28mm", width: "38mm" }} value={this.state.textarea} onChange={e => this.setState({ textarea: e.target.value })} />
                <p>תצוגת דוגמא:</p>
                <textarea style={{ backgroundColor: "darkgray", resize: "none", height: "28mm", width: "38mm" }} readOnly value={this.state.textarea} />
                <br />
                <br />
                {this.props.fsw &&
                  <FormControl style={{ width: "120px" }}><InputLabel>בחר חג</InputLabel>
                    <Select style={{ textAlign: "initial" }} value={this.state.nameH} onChange={e => { this.setState({ nameH: e.target.value }) }}>
                      <MenuItem value={'פסח'}>פסח</MenuItem><MenuItem value={'ראש השנה'}>ראש השנה</MenuItem>
                      <MenuItem value={'רמדאן'}>רמדאן</MenuItem></Select></FormControl>
                }
                <div className="Buttons">
                  <Button
                    style={{ backgroundColor: 'green' }} variant="contained" color="primary" size="small"
                    onMouseDown={() => { this.up() }} >עדכן טקסט</Button>
                  <Button
                    style={{ backgroundColor: 'brown' }} variant="contained" color="primary" size="small"
                    onMouseDown={() => { this.setState({ autocomplete: true, textarea: "" }) }} >ביטול</Button>
                </div>
              </div>}
            </div>
          </ThemeProvider>
        </StylesProvider>
      </div>
    );
  }
}
export default AddHoliday;