import React, { Component } from 'react';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import "./GetTicket.css";

class GetTicket extends Component {
    state = { ID: '', password: '', RedirectActions: false, iserror: false }

    componentDidMount() { document.body.addEventListener('keypress', this.UseLoginEnter); }
    componentWillUnmount() { document.body.removeEventListener('keypress', this.UseLoginEnter); }
    UseLoginEnter = e => {
        if (this.state.ID !== '') {
            if (e.keyCode === 13) {
                this.ticket()
            }
        }
    }

    ticket = () => {
        this.setState({ iserror: false })
        axios
            .get(`/FindPeople/${this.state.ID}`)
            .then(res => {
                if (res.data !== "") {
                    this.props.setPerson(res.data)
                    // console.log( this.props.history);
                    this.setState({ RedirectActions: true })
                }
                else {
                    this.setState({ iserror: true })
                }
            }).catch(() => {
                this.setState({ iserror: true })
            })
    }
    render() {
        const disabled = !this.state.ID || this.state.ID.length < 7;
        if (this.state.RedirectActions) { return <Redirect to='./Actions' /> }
        return (
            <div className='GetTicket'>
                <div className='main'>
                    <h1 className='animate-charcter'>ברוכים הבאים ליד ביד</h1>
                    <div className="groupLogin">
                        {/* <label className="label">אנא הקלד תעודת זהות</label> */}
                        <input placeholder="נא הקלד תעודת זהות" autoComplete="off" required onChange={e => this.setState({ ID: e.target.value })} type="number" className="input" />
                    </div>

                    <div className="groupLogin">
                        {this.state.iserror ? <p style={{ color: 'red' }}>שגיאה</p> : ''}
                        <button className="button" disabled={disabled} onMouseDown={() => { this.ticket() }}>אישור</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default GetTicket;