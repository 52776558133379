import React, { Component } from "react";
import { BrowserRouter, Switch, Route, Link } from "react-router-dom";
import GetTicket from './GetTicket';
import Actions from './Actions';
import "./Open.css";
import ReactFullscreen from 'react-easyfullscreen';
class Open extends Component {

    state = { Person: null }

    Person = (person) => {
        this.setState({ Person: person })
    }

    render() {
        return (
            <div className="Open">
                <BrowserRouter>
                {/* <div className="butS"> */}

                    <div className="butS">
                        <Link to='/GetTicket'>  לחץ למעבר למערכת פתוחה</Link> </div>
                    {/* <div >
                        <Link to='/Actions'>Actions</Link> </div> */}
                    <Switch>
                        <ReactFullscreen>
                            {({ ref, onRequest, onExit }) => (
                                <div
                                    ref={ref}
                                    style={{ width: 120, height: 120 }}
                                >
                                    <div className='Fullscreen'>
                                        <button onClick={() => { onRequest(); this.setState({ buttons: false }); }}>FullScreen</button>
                                        <button onClick={() => onExit()}>Screen</button>
                                    </div>
                                    <Route exact path='/GetTicket' render={() => <GetTicket setPerson={this.Person} />} />
                                    <Route exact path='/Actions' render={() => <Actions Person={this.state.Person} setPerson={this.Person} />} />
                                </div>
                            )}
                        </ReactFullscreen>
                    </Switch>
                </BrowserRouter>
            </div>
        );
    }
}

export default Open;