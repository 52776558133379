import React, { Component } from 'react';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import "./Actions.css";
class Actions extends Component {

    state = { RedirectGetTicket: false, redy: false }
    componentDidMount() {
        if (!this.props.Person) {
            this.setState({ RedirectGetTicket: true })
        }
        else {
            this.setState({ redy: true })
        }
    }

    exit() {
        this.props.setPerson("");
        this.setState({ RedirectGetTicket: true });
    }

    CreateAction = (action) => {
        axios
            .post("actions/createAction", {
                ID: this.props.Person.PersonID,
                FirstName: this.props.Person.FirstName,
                LastName: this.props.Person.LastName,
                Action: action,
                time: new Date()
            })
            .then(res => {
                if (res.status === 201) {
                    alert('הרישום בוצע בהצלחה')
                    this.setState({ RedirectGetTicket: true });
                }
                else {
                    console.log(`error code :${res.status}`)
                }
            }).catch(err => {
                console.log(err)
            });
    };



    render() {
        if (this.state.RedirectGetTicket) { return <Redirect to='./GetTicket' /> }
        return (
            <div className='Actions'>
                {this.state.redy && <h1 style={{marginTop:'10vh'}}>שלום {this.props.Person.FirstName} {this.props.Person.LastName}</h1>}
                <h3>אנא בחר/י את הפעולה שברצונך לבצע</h3>
                <div className='con'>
                    <div onMouseDown={() => { this.CreateAction(1) }} className='but'>חלוקה לשבת</div>
                    <div onMouseDown={() => { this.CreateAction(2) }} className='but'>חנות מסובסדת</div>
                    <div onMouseDown={() => { this.exit() }} className='but'>exit</div>
                    {/* <div className='but'>4</div> */}
                </div>
            </div>
        );
    }
}

export default Actions;